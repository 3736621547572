import Public from '@/projects/synergy/Public.vue';
import {
  createApp,
  h,
} from 'vue';
import router from '../router-public';
import { createPinia } from 'pinia';
import i18n from '@/shared/plugins/i18n';

const pinia = createPinia();
const appInstance = createApp({
  render: () => h(Public),
})
  .use(pinia)
  .use(i18n)
  .use(router);

appInstance.mount('#app');

if (location.href.indexOf('/my/') !== -1) {
  location.href = '/my?href=' + location.pathname;
}
