import { createI18n } from 'vue-i18n';
import { pluralizeVueI18N } from '@/shared/helpers/pluralizeVueI18N';
import merge from 'lodash/merge';

const numberFormats = {
  en: { currency: { style: 'currency', currency: 'USD' } },
  ru: {
    currency: {
      style: 'currency',
      currency: 'RUB',
      currencyDisplay: 'symbol',
    },
  },
};

function loadLocaleMessages() {
  const sharedLocales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const locales = require.context('@project/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: Record<string, any> = {};
  sharedLocales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = merge(sharedLocales(key), locales(key));
    }
  });
  return messages;
}

const i18n = createI18n({
  numberFormats,
  locale: window.localStorage.getItem('SF_LANG') ?? 'ru',
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
  ...pluralizeVueI18N(),
  silentTranslationWarn: true,
});

export default i18n;
