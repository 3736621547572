import {
  createRouter,
  createWebHistory,
} from 'vue-router';

const router = createRouter({
  'history': createWebHistory('/'),
  'routes': [
    {
      path: '/',
      name: 'Landing',
      component: () => import(/* webpackChunkName: "landing" */ '@/projects/synergy/pages/TheMain.vue'),
    },
    {
      path: '/my/:catchAll(.*)',
      name: 'Cabinet',
      component: () => import(/* webpackChunkName: "cabinet" */ '@/projects/synergy/pages/TheCabinet.vue'),
    },
    {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "not-found" */ '@/projects/synergy/pages/TheNotFound.vue'),
    },
  ],
});

export default router;
